.logo-img {
  height: 36px;
  // margin: -10px -4px 0px 0px;
}

$primary: rgb(0, 44, 144);
$navbar-dark-hover-color: #ffc107;

.inputError {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem 0.25rem 0.4rem;
}

// .my-btn {
//   display: block;
//   width: 100%;
//   cursor: pointer;
//   font-weight: 400;
//   text-align: center;
//   white-space: nowrap;
//   user-select: none;
//   padding: 0.375rem 0.75rem;
//   font-size: 1rem;
//   line-height: 1.5;
//   border-radius: 0.25rem;
//   // transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
//   //   box-shadow 0.15s ease-in-out;
// }

// .my-btn-white {
//   border: 1px solid rgb(138, 138, 138);
//   transition: all 0.2s linear 0s;
// }

.form-field-with-icon {
  background-color: #ffffff;
  border-radius: 0.25rem;
  border: 1px solid #cccccc;
  padding: 0.25rem 0.5rem 0.25rem 0.4rem;
}

.form-field-with-icon-input {
  border: none;
  background-color: rgba(255, 255, 255, 0);
}

.form-field-with-icon-input:focus {
  background-color: rgba(255, 255, 255, 0);
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

.form-btn {
  display: block;
  width: 100%;
  height: 2.1rem;
  background-color: #ffffff;
  border-radius: 0.25rem !important;
  border: 1px solid #cccccc;
  cursor: pointer;
  color: #616161;
  // fontFamily: 'Arial';
  // fontSize: '17px';
  text-decoration: none;
  // padding: 0.375rem 0.75rem;
  transition: all 0.2s linear 0s;
}

.form-btn:hover {
  background-color: #eeeeee;
}
.form-btn:active {
  position: relative;
  top: 1px;
}

.modal-z {
  z-index: 1000;
}

// textarea {
//   color: red;
// }

// textarea:focus {
//   border: 1px solid #cccccc !important;
// }

// input:focus {
//   // color: blue;
//   outline: 0px !important;
//   -webkit-appearance: none;
//   box-shadow: none !important;
// }

.about-img {
  position: relative;
  left: 0;
  display: inline;
}

.about-text {
  float: right;
}

.artFrame {
  border: 3px solid rgba(255, 255, 255, 0.5);
}
