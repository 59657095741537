.stopEmptiness {
  overflow: hidden;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.stopEmptiness0 {
  background-image: url('./img/stop-emptiness/0.jpg');
}

.stopEmptiness1 {
  background-image: url('./img/stop-emptiness/1.jpg');
}

.stopEmptiness2 {
  background-image: url('./img/stop-emptiness/2.jpg');
}

.stopEmptiness3 {
  background-image: url('./img/stop-emptiness/3.jpg');
}
