.writingEntry {
  height: 17rem;
  overflow: hidden;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.compositeLeviathan {
  background-image: url('./img/A-Composite-Leviathan.jpg');
}

.cqContemporaryChineseArtAndChristianity {
  background-image: url('./img/China-Quarterly--Contemporary-Chinese-Art-and-Christianity.jpg');
}

.luhringAugusting {
  background-image: url('./img/Luhring-Augustine.jpg');
}

.marcianoArtFoundation {
  background-image: url('./img/Marciano-Art-Foundation.jpg');
}

.theDeadPig {
  background-image: url('./img/The-Dead-Pig.jpg');
}

.liuDingLuYinghuaLuoXioming {
  background-image: url('./img/Liu-Ding--Lu-Yinghua--Luo-Xiaoming-cropped.jpg');
}

.magicianSpace {
  background-image: url('./img/Magician-Space.jpg');
}

.qinJin {
  background-image: url('./img/Qin-Jin.jpg');
}

.wangSishun {
  background-image: url('./img//Wang-Sishun.jpg');
}

.tabulaRasa {
  background-image: url('./img/Tabula-Rasa.jpg');
}

.antonyGormlyPost {
  background-image: url('./img/Antony-Gormley--Post.jpg');
}

.liuYue {
  background-image: url('./img/Liu-Yue.jpg');
}

.liRanSameOldCrowd {
  background-image: url('./img/Li-Ran--Same-Old-Crowd.jpg');
}

.feungJunlianDavid {
  background-image: url('./img/Feung-Junlian--David.png');
}

.laureProuvostGrandmasDream {
  background-image: url('./img/Laure-Prouvost--Grandmas-Dream.png');
}

.iiac {
  background-image: url('./img/第二届IAAC艺术评论奖.jpg');
}

.huangYongping {
  background-image: url('./img/黄永砅《羊祸》Huang Yongping.jpg');
}
