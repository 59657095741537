@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@700, 900&display=swap');

$PTSans: 'PT Sans', Helvetica, sans-serif;
$SourceSansPro: 'Source Sans Pro', Helvetica, sans-serif;

.font-logo {
  font-family: $SourceSansPro;
  font-weight: 700;
}

.font-writing-title {
  font-family: $PTSans;
  font-weight: 700;
}

.font-writing-publication {
  font-family: $PTSans;
  font-weight: 700;
  font-style: italic;
}

.font-normal {
  font-family: $PTSans;
  font-weight: 400;
}

.font-header {
  font-family: $PTSans;
}
